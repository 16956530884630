export const SURVEY_QUESTION_BASE_FRAGMENT = `
  fragment surveyQuestionBaseFragment on SurveyQuestion {
    uid
    label
    description
    type
    optional
    displayOrder
  }
`;
